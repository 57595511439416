import { InstagramLogo, FacebookLogo, XLogo, RedditLogo, LinkedinLogo } from "@phosphor-icons/react";
import { Fragment } from "react";

export default function Footer() {
  return (<>
    <footer className="socials max-sm:hidden footer bg-neutral text-neutral-content items-center p-2">
    <nav className="grid-flow-col gap-3 place-self-center items-center">
    <aside className="grid-flow-col items-center">
        <div>Copyright &copy; {new Date().getFullYear()}
        <span className="sm:hidden"><br/></span>
        <span className="max-sm:hidden">&nbsp;&nbsp;</span>
        <span className="ae-logo footerlogo">æ</span>
        <span className="max-sm:hidden">&nbsp;&nbsp;</span>
        <span>APERSU</span><span><b>ESTIMATING</b></span>
      </div>
    </aside>&nbsp;&nbsp;&nbsp;&nbsp;
    <a target="_blank" rel="noreferrer" href="https://www.facebook.com/apersuestimates/"><FacebookLogo  size={34}/></a>
    <a target="_blank" rel="noreferrer" href="https://www.instagram.com/apersuestimates/"><InstagramLogo  size={34}/></a>
    <a target="_blank" rel="noreferrer" href="https://x.com/apersuestimates"><XLogo  size={34}/></a>
    <a target="_blank" rel="noreferrer" href="https://www.reddit.com/r/apersuestimates/"><RedditLogo  size={34}/></a>
    <a target="_blank" rel="noreferrer" href="https://www.linkedin.com/company/apersuestimates/"><LinkedinLogo  size={34}/></a>
    </nav>
  </footer>

  <footer className="socials sm:hidden footer bg-neutral text-neutral-content items-center p-1">
  <nav className="grid-flow-col gap-2 pe-2 place-self-center items-center">
  <aside className="grid-flow-col items-center">
    <p>&copy; {new Date().getFullYear()} <span>APERSU</span><span><b>ESTIMATING</b></span></p>
  </aside>&nbsp;&nbsp;&nbsp;&nbsp;
  <a target="_blank" rel="noreferrer" href="https://www.facebook.com/apersu.estimating/"><FacebookLogo  size={25}/></a>
  <a target="_blank" rel="noreferrer" href="https://www.instagram.com/_apersu/"><InstagramLogo  size={25}/></a>
  <a target="_blank" rel="noreferrer" href="https://x.com/_apersu"><XLogo  size={25}/></a>
  <a target="_blank" rel="noreferrer" href="https://www.reddit.com/r/apersu/"><RedditLogo  size={25}/></a>
  <a target="_blank" rel="noreferrer" href="https://www.linkedin.com/company/apersuestimating/"><LinkedinLogo  size={25}/></a>
  </nav>
</footer>


  </>)
}

import React, { useState, useEffect, useMemo, useCallback } from "react";
import {
  doc,
  setDoc,
  collection,
  orderBy,
  serverTimestamp,
  getDocs,
  query,
} from "firebase/firestore";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { useNavigate, Link } from "react-router-dom";
import {
  ArrowCircleLeft,
  ArrowCircleRight,
  CalendarDots,
  CheckCircle,
  HouseLine,
  Info,
  Note,
  Wallet,
  XCircle,
} from "@phosphor-icons/react";
import { apersuDatabase, apersuStorage } from "../../firebase/config";
import { format, parse } from "date-fns";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import FileUploadComponent from "../FileUploadComponent";

function generateFriendlyId() {
  const now = new Date();
  const seed = now.getTime();
  const characters = "2346789ABCDEFGHJKLMNPQRTUVWXYZ";
  let result = "";

  for (let i = 0; i < 6; i++) {
    const randomIndex = Math.floor(seededRandom(seed + i) * characters.length);
    result += characters.charAt(randomIndex);
  }

  return result;
}

function seededRandom(seed) {
  const x = Math.sin(seed) * 10000;
  return x - Math.floor(x);
}

const NewEstimateForm = React.memo(({ uid }) => {
  const navigate = useNavigate();
  const colRef = useMemo(
    () => collection(apersuDatabase, `users/${uid}/estimates`),
    [uid]
  );

  // State declarations
  const [estimateTypes, setEstimateTypes] = useState([]);
  const [formStep, setFormStep] = useState(1);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [projectDate, setProjectDate] = useState("");
  const [projectProposal, setProjectProposal] = useState("Select");
  const [addressLine1, setAddressLine1] = useState("");
  const [addressLine2, setAddressLine2] = useState("");
  const [addressLine3, setAddressLine3] = useState("");
  const [projectAddressPostcode, setProjectAddressPostcode] = useState("");
  const [projectFileCheck1, setProjectFileCheck1] = useState(false);
  const [projectFiles, setProjectFiles] = useState([]);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [projectNotes, setProjectNotes] = useState("");
  const [projectNumber, setProjectNumber] = useState("");
  const [projectValue, setProjectValue] = useState("0");
  const [projectStatus] = useState("Received");
  const [projectStatusBadge] = useState("outline");
  const [projectStatusTooltip] = useState(
    "We have received your request and will process it shortly. We'll be in touch if we need any further information."
  );

  const fetchEstimateTypes = useCallback(async () => {
    try {
      const estimateTypesRef = collection(
        apersuDatabase,
        "users/default/estimateTypes"
      );
      const q = query(estimateTypesRef, orderBy("listOrder", "asc"));
      const snapshot = await getDocs(q);
      const types = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setEstimateTypes(types);
    } catch (error) {
      console.error("Error fetching estimate types:", error);
      toast.error("Error fetching estimate types. Please try again.");
    }
  }, []);

  useEffect(() => {
    fetchEstimateTypes();
  }, [fetchEstimateTypes]);

  useEffect(() => {
    setProjectNumber(generateFriendlyId());
  }, []);

  useEffect(() => {
    console.log("Updated project files:", projectFiles);
  }, [projectFiles]);

  const formStepNext = useCallback(() => setFormStep((cur) => cur + 1), []);
  const formStepPrev = useCallback(() => setFormStep((cur) => cur - 1), []);
  const cancelForm = useCallback(() => navigate("/MyEstimates"), [navigate]);

  const splitString = useMemo(
    () => projectProposal.split("|"),
    [projectProposal]
  );
  const projectSplitCost = splitString[0];
  const projectSplitAnA = splitString[1];
  const projectSplitProposal = splitString[2];
  const projectDateSelected = projectDate;
  const projectAddressLine1 = useMemo(
    () =>
      addressLine1
        .replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase())
        .trim(),
    [addressLine1]
  );
  const projectAddressLine2 = useMemo(
    () =>
      addressLine2
        .replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase())
        .trim(),
    [addressLine2]
  );
  const projectAddressLine3 = useMemo(
    () =>
      addressLine3
        .replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase())
        .trim(),
    [addressLine3]
  );
  const formatPostcodeA = useMemo(
    () =>
      projectAddressPostcode
        .trim()
        .split(" ")
        .join("")
        .toUpperCase()
        .slice(0, -3),
    [projectAddressPostcode]
  );
  const formatPostcodeB = useMemo(
    () =>
      projectAddressPostcode.trim().split(" ").join("").toUpperCase().slice(-3),
    [projectAddressPostcode]
  );
  const projectFormattedPostcode = `${formatPostcodeA} ${formatPostcodeB}`;

  const handleFileChange = useCallback((files) => {
    console.log("Files selected:", files);
    setProjectFiles(files);
  }, []);

  const uploadFiles = useCallback(
    async (docId) => {
      console.log("Starting file upload. Number of files:", projectFiles.length);
      const formatDate = () => {
        const now = new Date();
        return now.toLocaleString('en-GB', {
          day: '2-digit',
          month: '2-digit',
          year: '2-digit',
          hour12: false
        }).replace(/[/:]/g, '');
      };
  
      const sanitizeFileName = (name) => {
        return name.replace(/\s+/g, '');
      };
  
      const uploadPromises = projectFiles.map((file, index) => {
        console.log(`Preparing to upload file ${index + 1}:`, file.name);
        const fileExtension = file.name.split('.').pop();
        const sanitizedAddressLine1 = sanitizeFileName(projectAddressLine1);
        const fileName = `${sanitizedAddressLine1}_${formatDate()}_${index + 1}.${fileExtension}`;
  
        const storageRef = ref(
          apersuStorage,
          `projectFiles/${uid}/${docId}/${fileName}`
        );
        const uploadTask = uploadBytesResumable(storageRef, file);
  
        return new Promise((resolve, reject) => {
          uploadTask.on(
            "state_changed",
            (snapshot) => {
              const progress =
                (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
              console.log(`Upload progress for ${file.name}: ${progress.toFixed(2)}%`);
              setUploadProgress(progress);
            },
            (error) => {
              console.error(`Error uploading ${file.name}:`, error);
              reject(error);
            },
            async () => {
              try {
                const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
                console.log(`File ${file.name} uploaded successfully. URL:`, downloadURL);
                resolve({ name: fileName, url: downloadURL });
              } catch (error) {
                console.error(`Error getting download URL for ${file.name}:`, error);
                reject(error);
              }
            }
          );
        });
      });
  
      try {
        const results = await Promise.all(uploadPromises);
        console.log("All files uploaded successfully:", results);
        return results;
      } catch (error) {
        console.error("Error during file upload:", error);
        throw error;
      }
    },
    [projectFiles, projectAddressLine1, uid]
  );

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      setIsSubmitting(true);
      setUploadProgress(0);
      console.log("Starting form submission. Files to upload:", projectFiles);
      try {
        const newDocRef = doc(
          collection(apersuDatabase, `users/${uid}/estimates`)
        );
        const docId = newDocRef.id;

        const fileUrls = await uploadFiles(docId);
        console.log("File URLs after upload:", fileUrls);

        await setDoc(newDocRef, {
          _customerID: uid,
          _estimateID: docId,
          _dateSubmitted: serverTimestamp(),
          _dateUpdated: serverTimestamp(),
          projectSplitProposal,
          projectDateSelected,
          projectAddressLine1,
          projectAddressLine2,
          projectAddressLine3,
          projectFormattedPostcode,
          projectSplitCost,
          projectSplitAnA,
          projectValue,
          projectNotes,
          projectNumber,
          projectStatus,
          projectStatusBadge,
          projectStatusTooltip,
          projectFiles: fileUrls,
        });

        console.log("Document successfully written to Firestore");
        toast.success("Estimate submitted successfully!", {
          autoClose: 2000,
        });
        formStepNext();
      } catch (error) {
        console.error("Error submitting estimate:", error);
        toast.error("Error submitting estimate. Please try again.", {
          autoClose: 2000,
        });
      } finally {
        setIsSubmitting(false);
      }
    },
    [
      uid,
      projectSplitProposal,
      projectDateSelected,
      projectAddressLine1,
      projectAddressLine2,
      projectAddressLine3,
      projectFormattedPostcode,
      projectSplitCost,
      projectSplitAnA,
      projectValue,
      projectNotes,
      projectNumber,
      projectStatus,
      projectStatusBadge,
      projectStatusTooltip,
      uploadFiles,
      formStepNext,
      projectFiles,
    ]
  );

  const isDateValid = useCallback(() => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const selectedDate = new Date(projectDate);
    return selectedDate > today;
  }, [projectDate]);

  const isStep2Valid = useCallback(
    () =>
      projectProposal !== "Select" &&
      addressLine1.trim() !== "" &&
      projectAddressPostcode.trim() !== "",
    [projectProposal, addressLine1, projectAddressPostcode]
  );

  const isStep3Valid = useCallback(
    () => projectFileCheck1 && projectFiles.length > 0,
    [projectFileCheck1, projectFiles]
  );

  const FormattedDate = useCallback(({ dateString }) => {
    const date = parse(dateString, "yyyy-MM-dd", new Date());
    const formattedDate = format(date, "EEEE, do MMMM");
    return formattedDate;
  }, []);

  return (
    <>
      <ToastContainer />
      <form onSubmit={handleSubmit}>
        {/* -------- Step 1; -------- */}
        {formStep === 1 && (
          <section>
            <div className="card shadow-xl lg:px-10 mb-10 sm:pt-20 pb-20 lg:w-10/12 md:w-full mx-auto">
              <div
                className="radial-progress mx-auto sm:hidden"
                style={{ "--value": 17 }}
                role="progressbar">
                1/6
              </div>
              <ul className="steps max-sm:hidden w-fit mx-auto pb-10">
                <li data-content="•" className="step step-primary">
                  Select Date
                </li>
                <li data-content="" className="step step-primary">
                  Project Information
                </li>
                <li data-content="" className="step step-primary">
                  File Upload
                </li>
                <li data-content="" className="step step-primary">
                  Summary
                </li>
                <li data-content="" className="step step-primary">
                  Payment
                </li>
                <li data-content="" className="step step-primary">
                  Confirmation
                </li>
              </ul>
              <div className="w-10/12 mx-auto mb-8 pageSubtitle divider divider-primary">
                Select a Date
              </div>
              <div className="label">
                <span className="me-60 ms-40 estimateSummaryContent text-left">
                  Select an available date from the calendar.
                  <br />
                  We will start working on your estimate on this date and aim to
                  have it completed within two working days.
                </span>
              </div>
              <input
                type="date"
                required
                className="input input-bordered input-secondary w-2/3 mx-auto"
                onChange={(e) => setProjectDate(e.target.value)}
                value={projectDate}
                id="projectDate"
                name="projectDate"
                min={new Date().toISOString().split("T")[0]}
              />
              <br />
              <div className="join grid grid-cols-2 sm:w-1/2 mx-auto">
                <button
                  type="button"
                  onClick={cancelForm}
                  className="join-item btn btn-primary">
                  <XCircle size={25} />
                  Cancel
                </button>
                <button
                  type="button"
                  disabled={!isDateValid()}
                  onClick={formStepNext}
                  className="join-item btn btn-secondary">
                  Next <ArrowCircleRight size={25} />
                </button>
              </div>
            </div>
            <br />
            <br />
            <br />
          </section>
        )}
        {/* ------ Step 1 END; ------ */}

        {/* -------- Step 2; -------- */}
        {formStep === 2 && (
          <section className="">
            <div className="card shadow-xl lg:px-10 mb-10 sm:pt-20 pb-20 lg:w-10/12 md:w-full mx-auto">
              <div
                className="radial-progress mx-auto sm:hidden"
                style={{ "--value": 34 }}
                role="progressbar">
                2/6
              </div>
              <ul className="steps max-sm:hidden w-fit mx-auto pb-10">
                <li data-content="✓" className="step step-primary">
                  Select Date
                </li>
                <li data-content="•" className="step step-primary">
                  Project Information
                </li>
                <li data-content="" className="step step-primary">
                  File Upload
                </li>
                <li data-content="" className="step step-primary">
                  Summary
                </li>
                <li data-content="" className="step step-primary">
                  Payment
                </li>
                <li data-content="" className="step step-primary">
                  Confirmation
                </li>
              </ul>
              <div className="w-10/12 mx-auto mb-8 pageSubtitle divider divider-primary">
                Project Information
              </div>
              <div className="label">
                <span className="me-auto ms-40 estimateSummaryContent text-left">
                  Select the project type.
                </span>
              </div>
              <select
                className="select select-bordered select-secondary sm:w-2/3 w-11/12 mx-auto"
                onChange={(e) => setProjectProposal(e.target.value)}
                value={projectProposal}
                id="projectProposal">
                <option value="Select" disabled>
                  Please select the project type
                </option>
                {estimateTypes &&
                  estimateTypes.map((estimateType) => (
                    <option key={estimateType.id} value={estimateType.details}>
                      {estimateType.title}
                    </option>
                  ))}
              </select>
              <br />
              <div className="label">
                <span className="lme-auto ms-40 estimateSummaryContent">
                  Enter the project address
                </span>
              </div>
              <label className="input input-bordered flex items-center gap-2 sm:w-2/3 w-11/12 mx-auto">
                <span className="badge badge-accent badge-xs"></span>
                <input
                  type="text"
                  className="grow"
                  id="addressLine1"
                  name="addressLine1"
                  onChange={(e) => setAddressLine1(e.target.value)}
                  value={addressLine1}
                  placeholder="Address Line 1"
                />
              </label>
              <label className="input input-bordered flex items-center gap-2 sm:w-2/3 w-11/12 mx-auto">
                &nbsp;&nbsp;&nbsp;
                <input
                  type="text"
                  className="grow"
                  id="addressLine2"
                  onChange={(e) => setAddressLine2(e.target.value)}
                  value={addressLine2}
                  placeholder="Address Line 2"
                />
              </label>
              <label className="input input-bordered flex items-center gap-2 sm:w-2/3 w-11/12 mx-auto">
                &nbsp;&nbsp;&nbsp;
                <input
                  type="text"
                  className="grow"
                  id="addressLine3"
                  onChange={(e) => setAddressLine3(e.target.value)}
                  value={addressLine3}
                  placeholder="Address Line 3"
                />
              </label>
              <label className="input input-bordered flex items-center gap-2 sm:w-2/3 w-11/12 mx-auto">
                <span className="badge badge-accent badge-xs"></span>
                <input
                  type="text"
                  className="grow"
                  id="postCode"
                  maxLength={8}
                  onChange={(e) => setProjectAddressPostcode(e.target.value)}
                  value={projectAddressPostcode}
                  placeholder="Post Code"
                  pattern="([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9][A-Za-z]?))))\s?[0-9][A-Za-z]{2})"
                />
              </label>
              <br />

              <div className="label">
                <span className="me-auto ms-40 estimateSummaryContent">
                  Enter any additional project information
                </span>
              </div>
              <textarea
                className="textarea textarea-bordered sm:w-2/3 w-11/12 mx-auto h-40"
                placeholder='Enter any additional relevant project information here. eg. &#13;"The drawings show a rear extension, but this has already been completed", &#13;"Please price for the works to the ground floor only",&#13;"Please make no allowance for a new heating system"'
                onChange={(e) => setProjectNotes(e.target.value)}
                value={projectNotes}></textarea>
              <br />
              <div className="join grid grid-cols-2 sm:w-1/2 mx-auto">
                <button
                  type="button"
                  onClick={formStepPrev}
                  className="join-item btn btn-primary">
                  <ArrowCircleLeft size={25} />
                  Back
                </button>
                <button
                  type="button"
                  disabled={!isStep2Valid()}
                  onClick={formStepNext}
                  className="join-item btn btn-secondary">
                  Next <ArrowCircleRight size={25} />
                </button>
              </div>
            </div>
            <br />
            <br />
            <br />
          </section>
        )}
        {/* ------ Step 2 END; ------ */}

        {/* -------- Step 3; -------- */}
        {formStep === 3 && (
          <section className="">
            <div className="card shadow-xl lg:px-10 mb-10 sm:pt-20 pb-20 lg:w-10/12 md:w-full mx-auto">
              <div
                className="radial-progress mx-auto sm:hidden"
                style={{ "--value": 50 }}
                role="progressbar">
                3/6
              </div>
              <ul className="steps max-sm:hidden w-fit mx-auto pb-10">
                <li data-content="✓" className="step step-primary">
                  Select Date
                </li>
                <li data-content="✓" className="step step-primary">
                  Project Information
                </li>
                <li data-content="•" className="step step-primary">
                  File Upload
                </li>
                <li data-content="" className="step step-primary">
                  Summary
                </li>
                <li data-content="" className="step step-primary">
                  Payment
                </li>
                <li data-content="" className="step step-primary">
                  Confirmation
                </li>
              </ul>
              <div className="w-10/12 mx-auto mb-8 pageSubtitle divider divider-primary">
                Upload Files
              </div>
              <div className="flex flex-col w-11/12 sm:w-2/3 mx-auto">
                <div className="form-control w-auto">
                  <label className="label cursor-pointer">
                    <span className="label-text text-left">
                      I confirm that the file(s) being uploaded contains all
                      relevant information required to complete the estimate.
                      <br />
                    </span>
                    <input
  type="checkbox"
  className="toggle toggle-info"
  onChange={(e) => setProjectFileCheck1(e.target.checked)}
  checked={projectFileCheck1}
/>
                    &nbsp;&nbsp;&nbsp;
                    <button
                      className="btn btn-sm btn-info"
                      type="button"
                      onClick={() =>
                        document
                          .getElementById("modal_file_upload_info")
                          .showModal()
                      }>
                      <Info size={15} />
                      What's this?
                    </button>
                  </label>
                </div>
              </div>
              <FileUploadComponent onFileChange={handleFileChange} />
              {uploadProgress > 0 && (
                <progress
                  className="progress w-56"
                  value={uploadProgress}
                  max="100"></progress>
              )}
              <div className="join grid grid-cols-2 sm:w-1/2 mx-auto pt-10">
                <button
                  type="button"
                  onClick={formStepPrev}
                  className="join-item btn btn-primary">
                  <ArrowCircleLeft size={25} />
                  Back
                </button>
                <button
                  type="button"
                  disabled={!isStep3Valid()}
                  onClick={formStepNext}
                  className="join-item btn btn-secondary">
                  Next <ArrowCircleRight size={25} />
                </button>
              </div>
            </div>

            <br />
            <br />
            <br />
          </section>
        )}
        {/* ------ Step 3 END; ------ */}

        {/* -------- Step 4; -------- */}
        {formStep === 4 && (
          <section className="">
            <div className="card shadow-xl lg:px-10 mb-10 sm:pt-20 pb-20 lg:w-10/12 md:w-full mx-auto">
              <div
                className="radial-progress mx-auto sm:hidden"
                style={{ "--value": 66 }}
                role="progressbar">
                4/6
              </div>
              <ul className="steps max-sm:hidden w-fit mx-auto pb-10">
                <li data-content="✓" className="step step-primary">
                  Select Date
                </li>
                <li data-content="✓" className="step step-primary">
                  Project Information
                </li>
                <li data-content="✓" className="step step-primary">
                  File Upload
                </li>
                <li data-content="•" className="step step-primary">
                  Summary
                </li>
                <li data-content="" className="step step-primary">
                  Payment
                </li>
                <li data-content="" className="step step-primary">
                  Confirmation
                </li>
              </ul>
              <div className="w-10/12 mx-auto mb-8 pageSubtitle divider divider-primary">
                Summary
              </div>

              <div className="text-left px-5 sm:px-20">
                <>
                  <div className="border-2 border-primary rounded-lg my-5 px-5 py-3">
                    <div className="estimateSummarySubtitle">
                      Estimate for {projectSplitAnA}{" "}
                      <b>{projectSplitProposal}</b>
                    </div>
                  </div>
                </>
                <>
                  <div className="border-2 border-primary rounded-lg my-5 px-5 py-3">
                    <div className="estimateSummarySubtitle">
                      <HouseLine />
                      {projectAddressLine1},<br />
                      {projectAddressLine2 && (
                        <>
                          {projectAddressLine2},<br />
                        </>
                      )}
                      {projectAddressLine3 && (
                        <>
                          {projectAddressLine3},<br />
                        </>
                      )}
                      {projectFormattedPostcode}.
                    </div>
                  </div>
                </>
                <>
                  <div className="border-2 border-primary rounded-lg my-5 px-5 py-3">
                    <div className="estimateSummarySubtitle">
                      <CalendarDots />
                      Pricing to commence on{" "}
                      <b>
                        <FormattedDate dateString={projectDate} />
                      </b>
                      .
                    </div>
                  </div>
                </>
                {projectNotes && (
                  <>
                    {" "}
                    <div className="border-2 border-primary rounded-lg my-5 px-5 py-3">
                      <div className="estimateSummarySubtitle">
                        <Note />
                        Project-specific notes and/or instructions:
                        <br />
                      </div>
                      <div className="estimateSummaryContent">
                        {projectNotes}
                      </div>{" "}
                    </div>
                  </>
                )}

                <>
                  <div className="border-2 border-primary rounded-lg my-5 px-5 py-3">
                    <div className="estimateSummarySubtitle">
                      <Wallet />
                      Total to pay:
                    </div>
                    <div className="estimateSummaryContent">
                      £{projectSplitCost}.00 + VAT
                    </div>{" "}
                  </div>
                </>
              </div>
              <br />
              <div className="join grid grid-cols-2 sm:w-1/2 mx-auto">
                <button
                  type="button"
                  onClick={formStepPrev}
                  className="join-item btn btn-primary">
                  <ArrowCircleLeft size={25} />
                  Back
                </button>
                <button
                  type="button"
                  onClick={formStepNext}
                  className="join-item btn btn-secondary">
                  Next <ArrowCircleRight size={25} />
                </button>
              </div>
            </div>
            <br />
            <br />
            <br />
          </section>
        )}
        {/* ------ Step 4 END; ------ */}

        {/* -------- Step 5; -------- */}

        {formStep === 5 && (
          <section className="">
            <div className="card shadow-xl lg:px-10 mb-10 sm:pt-20 pb-20 lg:w-10/12 md:w-full mx-auto">
              <div
                className="radial-progress mx-auto sm:hidden"
                style={{ "--value": 82 }}
                role="progressbar">
                5/6
              </div>
              <ul className="steps max-sm:hidden w-fit mx-auto pb-10">
                <li data-content="✓" className="step step-primary">
                  Select Date
                </li>
                <li data-content="✓" className="step step-primary">
                  Project Information
                </li>
                <li data-content="✓" className="step step-primary">
                  File Upload
                </li>
                <li data-content="✓" className="step step-primary">
                  Summary
                </li>
                <li data-content="•" className="step step-primary">
                  Payment
                </li>
                <li data-content="" className="step step-primary">
                  Confirmation
                </li>
              </ul>
              <div className="w-10/12 mx-auto mb-8 pageSubtitle divider divider-primary">
                Payment
              </div>
              <div className="text-left px-5 sm:px-20"></div>
              <br />
              <div className="join grid grid-cols-2 sm:w-1/2 mx-auto">
                <button
                  type="button"
                  onClick={formStepPrev}
                  className="join-item btn btn-primary"
                  disabled={isSubmitting}>
                  <ArrowCircleLeft size={25} />
                  Back
                </button>
                <button
                  type="submit"
                  className="join-item btn btn-secondary"
                  disabled={isSubmitting}>
                  {isSubmitting ? (
                    <>
                      <span className="loading loading-spinner"></span>
                      Submitting...
                    </>
                  ) : (
                    <>
                      Submit and Pay <ArrowCircleRight size={25} />
                    </>
                  )}
                </button>
              </div>
              {isSubmitting && (
                <div className="mt-4">
                  <p>Uploading files... {uploadProgress.toFixed(0)}%</p>
                  <progress
                    className="progress w-56"
                    value={uploadProgress}
                    max="100"></progress>
                </div>
              )}
            </div>

            <br />
            <br />
            <br />
          </section>
        )}
        {/* ------ Step 5 END; ------ */}

        {/* -------- Step 6; -------- */}

        {formStep === 6 && (
          <section className="">
            <div className="card shadow-xl lg:px-10 mb-10 sm:pt-20 pb-20 lg:w-10/12 md:w-full mx-auto">
              <div
                className="radial-progress mx-auto sm:hidden"
                style={{ "--value": 100 }}
                role="progressbar">
                6/6
              </div>
              <ul className="steps max-sm:hidden w-fit mx-auto pb-10">
                <li data-content="✓" className="step step-primary">
                  Select Date
                </li>
                <li data-content="✓" className="step step-primary">
                  Project Information
                </li>
                <li data-content="✓" className="step step-primary">
                  File Upload
                </li>
                <li data-content="✓" className="step step-primary">
                  Summary
                </li>
                <li data-content="✓" className="step step-primary">
                  Payment
                </li>
                <li data-content="•" className="step step-primary">
                  Confirmation
                </li>
              </ul>
              <div className="label">
                <span className="pageSubtitle mx-auto">Thank You!</span>
              </div>
              <div className="text-left px-5 sm:px-20"></div>
              <br />
              <div className="join grid grid-cols-1 sm:w-1/2 mx-auto">
                <Link to="/MyEstimates">
                  <button className="btn sm:w-2/3 btn-secondary">
                    Finish <CheckCircle size={25} />
                  </button>
                </Link>
              </div>
            </div>
            <br />
            <br />
            <br />
          </section>
        )}
        {/* ------ Step 6 END; ------ */}
      </form>

      {/* --------- MODAL --------- */}
      <dialog id="modal_file_upload_info" className="modal">
        <div className="modal-box w-10/12 max-w-4xl">
          <h3 className="font-bold text-lg">Relevant information</h3>
          <div className="py-2 ps-3 text-left">
            We require relevant information to be submitted so we can work
            efficiently on your estimate
          </div>
          <div className="py-0 ps-10 text-left">Yes please:</div>
          <div className="py-0 ps-20 text-left">1</div>
          <div className="py-0 ps-20 text-left">2</div>
          <div className="py-0 ps-20 text-left">3</div>
          <div className="py-0 ps-20 text-left">4</div>
          <div className="py-0 ps-20 text-left">5</div>
          <div className="py-0 ps-20 text-left">6</div>
          <div className="py-0 ps-20 text-left">7</div>
          <div className="py-0 ps-20 text-left">8</div>
          <div className="py-0 ps-20 text-left">9</div>
          <div className="py-0 ps-10 text-left">No, thank you:</div>
          <div className="py-0 ps-20 text-left">1</div>
          <div className="py-0 ps-20 text-left">2</div>
          <div className="py-0 ps-20 text-left">3</div>
          <div className="py-0 ps-20 text-left">4</div>
          <div className="py-0 ps-20 text-left">5</div>
          <div className="py-0 ps-20 text-left">6</div>
          <div className="py-0 ps-20 text-left">7</div>
          <div className="py-0 ps-20 text-left">8</div>
          <div className="py-0 ps-20 text-left">9</div>
          <div className="modal-action">
            <form method="dialog">
              {/* if there is a button in form, it will close the modal */}
              <button className="btn lg:btn-md btn-primary">
                <CheckCircle size={35} />
                &nbsp;OK
              </button>
            </form>
          </div>
        </div>
      </dialog>
      {/* ------- MODAL END ------- */}
    </>
  );
});

export default NewEstimateForm;

import { XCircle } from '@phosphor-icons/react';
import React, { useState, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';

const FileUploadComponent = ({ onFileChange }) => {
  const [files, setFiles] = useState([]);

  const onDrop = useCallback((acceptedFiles) => {
    setFiles((prevFiles) => {
      const updatedFiles = [...prevFiles, ...acceptedFiles];
      onFileChange(updatedFiles);
      return updatedFiles;
    });
  }, [onFileChange]);

  const removeFile = useCallback((fileToRemove) => {
    setFiles((prevFiles) => {
      const updatedFiles = prevFiles.filter(file => file !== fileToRemove);
      onFileChange(updatedFiles);
      return updatedFiles;
    });
  }, [onFileChange]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: { 'application/pdf': ['.pdf'] },
    multiple: true
  });

  return (
    <div className="w-11/12 sm:w-2/3 mx-auto">
      <div {...getRootProps()} className={`dropzone p-6 border-2 border-dashed rounded-lg text-center cursor-pointer ${isDragActive ? 'border-secondary bg-secondary bg-opacity-10' : 'border-gray-300'}`}>
        <input {...getInputProps()} />
        {isDragActive ? (
          <p>Drop the files here ...</p>
        ) : (
          <p>Drag 'n' drop some files here, or click to select files</p>
        )}
      </div>
      {files.length > 0 && (
        <div className="mt-4">
          <h4 className="font-semibold">Uploaded files:</h4>
          <ul className="list-disc pl-5">
            {files.map((file, index) => (
              <li key={index} className="flex justify-between items-center">
                <span>{file.name}</span>
                <button 
                  onClick={() => removeFile(file)}
                  className="ml-2 text-red-500 hover:text-red-700"
                ><XCircle size={20} />
                  Remove
                </button>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default FileUploadComponent;
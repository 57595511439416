import { initializeApp } from 'firebase/app';
import { getFirestore, Timestamp } from "firebase/firestore";
import { 
  getAuth, 
  PhoneAuthProvider,
  multiFactor,
  PhoneMultiFactorGenerator,
  EmailAuthProvider,
  reauthenticateWithCredential,
  RecaptchaVerifier,
  getMultiFactorResolver
} from "firebase/auth";
import { getStorage } from "firebase/storage";
import { getFunctions } from 'firebase/functions';

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCJP85MehnzVfhKpum3wLYnFHZkVNVRtPA",
  authDomain: "apersutechnology.firebaseapp.com",
  databaseURL: "https://apersutechnology-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "apersutechnology",
  storageBucket: "apersutechnology.appspot.com",
  messagingSenderId: "611050399801",
  appId: "1:611050399801:web:a0d28a9364cbcc1e4bc5e8",
  measurementId: "G-PXBX4TK661"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize services
const apersuDatabase = getFirestore(app);
const apersuAuthentication = getAuth(app);
const apersuStorage = getStorage(app);
const functions = getFunctions(app);

// Export services and functions
export { 
  apersuDatabase, 
  apersuAuthentication, 
  apersuStorage, 
  functions, 
  app,
  PhoneAuthProvider,
  multiFactor,
  PhoneMultiFactorGenerator,
  EmailAuthProvider,
  reauthenticateWithCredential,
  RecaptchaVerifier,
  getMultiFactorResolver,
  Timestamp 
};